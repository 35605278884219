import { useMemo } from 'react';

import { GameEventsSort, QueryGameEventsResponse, GameEventsFilterField } from '@playq/octopus2-liveops';
import { OptionsFilter } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';

import { UseQueryOptionsExtended } from '/api/service-hooks';

import { useGameEventsQuery } from './useGameEventsQuery';

const sortBy: GameEventsSort[] = [];

export const useGameEventsQueryByIds = (
  ids: number[],
  options?: UseQueryOptionsExtended<QueryGameEventsResponse | undefined, GenericFailure | Error>
) => {
  const { filterBy, iterator } = useMemo(
    () => ({
      iterator: new OffsetLimit({ offset: 0, limit: ids.length }),
      filterBy: {
        [GameEventsFilterField.ID]: new OptionsFilter({
          options: ids.map((id) => String(id)),
        }),
      },
    }),
    [ids]
  );

  return useGameEventsQuery(iterator, sortBy, filterBy, {
    ...options,
    enabled: options?.enabled !== false && ids.length > 0,
  });
};
