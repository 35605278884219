import { useEffect, useState } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { FlowID } from '@playq/octopus-common';
import { AppFlowResponse, Flow } from '@playq/octopus2-liveops';

import { useEitherQuery } from '/api/service-hooks';
import { services2 } from '/api/services2';

export const flowRetrieveKeys: unknown[] = ['flow', 'retrieve'];

export const getFlowRetrieveKey = (id?: FlowID) => flowRetrieveKeys.concat(id?.serialize());

export const useFlowRetrieve = (
  id?: FlowID,
  version?: number,
  options?: UseQueryOptions<AppFlowResponse | undefined, GenericFailure | Error>
) => {
  const { mutate, ...res } = useEitherQuery(
    getFlowRetrieveKey(id),
    () => {
      if (id) {
        return services2.flowsService.retrieve(id, version);
      }
    },
    options
  );

  return res;
};

// TODO Figure out a way to move this to greenlet. The problem is in the module
// that doesn't get imported into a web worker. Sample:
// const asyncFlowParser: (content: string) => Promise<Flow> = greenlet(async (content: string) => {
const asyncFlowParser = (content: string): Promise<Flow> => {
  return new Promise((resolve, reject) => {
    try {
      const entity = new Flow(JSON.parse(content));
      resolve(entity);
    } catch (failure) {
      reject(failure);
    }
  });
};

export const useParsedFlowRetrieve = (...args: Parameters<typeof useFlowRetrieve>) => {
  const [parsedFlow, setParsedFlow] = useState<Flow | undefined>(undefined);
  const { data, ...res } = useFlowRetrieve(...args);
  useEffect(() => {
    if (!data) {
      return undefined;
    }
    asyncFlowParser(data.flow.content).then(setParsedFlow).catch(console.error);
  }, [data]);
  return { flow: parsedFlow, ...res };
};
