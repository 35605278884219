import { useMemo } from 'react';

import { QueryFlowsResponse, FlowsFilterField, FlowsSort } from '@playq/octopus2-liveops';
import { OptionsFilter } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';

import { UseQueryOptionsExtended } from '/api/service-hooks';

import { useFlowsQuery } from './useFlowsQuery';

const sortBy: FlowsSort[] = [];

export const useFlowsQueryByIds = (
  ids: number[],
  options?: UseQueryOptionsExtended<QueryFlowsResponse | undefined, GenericFailure | Error>
) => {
  const { filterBy, iterator } = useMemo(
    () => ({
      iterator: new OffsetLimit({ offset: 0, limit: ids.length }),
      filterBy: {
        [FlowsFilterField.ID]: new OptionsFilter({
          options: ids.map((id) => String(id)),
        }),
      },
    }),
    [ids]
  );

  return useFlowsQuery(iterator, sortBy, filterBy, {
    ...options,
    enabled: options?.enabled !== false && ids.length > 0,
  });
};
