import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { OptionsFilter } from '@playq/octopus-common';
import { ExperimentsSort, QueryExperimentsResponse, ExperimentsFilterField } from '@playq/octopus2-analytics';

import { UseQueryOptionsExtended } from '/api/service-hooks';
import { appToolkit } from '/store';

import { useExperimentsQuery } from './useExperimentsQuery';

const emptySort: ExperimentsSort[] = [];

export const useExperimentsQueryByIds = (
  ids: number[],
  options?: UseQueryOptionsExtended<QueryExperimentsResponse | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const { filterBy, iterator } = useMemo(
    () => ({
      iterator: new OffsetLimit({ offset: 0, limit: ids.length }),
      filterBy: {
        [ExperimentsFilterField.ID]: new OptionsFilter({
          options: ids.map((id) => String(id)),
        }),
      },
    }),
    [ids]
  );

  return useExperimentsQuery(appID, iterator, emptySort, filterBy, {
    ...options,
    enabled: options?.enabled !== false && ids.length > 0,
  });
};
