import { useCallback, useEffect } from 'react';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { GenericFailure } from '@playq/services-shared';
import { ExperimentID, AppExperimentResponse, AppExperiment, ExperimentCreateData } from '@playq/octopus2-analytics';

import { unwrapEither } from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { generateId } from '/helpers';

import { appExperimentsService } from './constants';

export const experimentRetrieveKeys: unknown[] = ['experiment', 'retrieve'];

export type UseExperimentRetrieveOptions = {
  id?: number;
  version?: number;
  trustCache?: boolean;
  copyCount?: number;
  onError?: (err: GenericFailure | Error) => void;
  onSuccess: (res: AppExperimentResponse, version?: number, copyCount?: number) => void;
};

export type RetrieveExperimentArgs = {
  id: number;
  version?: number;
  copyCount?: number;
};

export const useExperimentRetrieve = (options: UseExperimentRetrieveOptions) => {
  const { id: cacheId, version: cacheVersion, onSuccess, onError } = options;

  const queryClient = useQueryClient();

  const appID = useSelector(appToolkit.selectors.appID);

  const cacheExperimentID = generateId(ExperimentID, appID?.id, cacheId);
  const cachedExperimentQueryKey = experimentRetrieveKeys.concat(cacheExperimentID?.serialize(), cacheVersion);
  const cachedExperiment = queryClient.getQueryData<AppExperimentResponse>(cachedExperimentQueryKey);

  const isFetching = useIsFetching(cachedExperimentQueryKey);

  const retrieveExperiment = useCallback(
    async ({ id, version, copyCount }: RetrieveExperimentArgs) => {
      if (!appID) {
        snackbarService.error('App should be selected.');
        return;
      }

      const expID = generateId(ExperimentID, appID.id, +id);
      if (!expID) {
        return;
      }

      const queryKey = experimentRetrieveKeys.concat(expID.serialize(), version);

      try {
        const experiment =
          cacheId !== undefined
            ? cachedExperiment
            : await queryClient.fetchQuery(
                queryKey,
                () => unwrapEither(appExperimentsService.retrieve(expID, version)),
                {
                  initialData: cachedExperiment,
                }
              );
        if (experiment) {
          onSuccess(experiment, version, copyCount);
        }
      } catch (err) {
        const error = err as GenericFailure | Error;
        if (onError) {
          onError(error);
        } else {
          snackbarService.genericFailure(error);
        }
      }
    },
    [appID, cacheId, cachedExperiment, onError, onSuccess, queryClient]
  );

  const mutateUpdatedExperiment = useCallback(
    (expID: ExperimentID, updatedData: ExperimentCreateData) => {
      const queryKey = experimentRetrieveKeys.concat(expID.serialize());
      const cachedData = queryClient.getQueryData<AppExperimentResponse>(queryKey);
      if (cachedData) {
        const cloned = new AppExperimentResponse(cachedData.serialize());
        cloned.lastVersion += 1;
        cloned.experiment = new AppExperiment(cachedData.experiment.serialize());
        cloned.experiment.name = updatedData.name;
        cloned.experiment.endsAtAsString = updatedData.endsAtAsString;
        cloned.experiment.startsAtAsString = updatedData.startsAtAsString;
        cloned.experiment.tags = updatedData.tags;
        cloned.experiment.content = updatedData.content;
        queryClient.setQueryData(queryKey, cloned);
      }
    },
    [queryClient]
  );

  useEffect(
    function getCachedDataAfterFetching() {
      if (cacheId !== undefined && !isFetching && cachedExperiment) {
        retrieveExperiment({ id: cacheId, version: cacheVersion });
      }
    },
    [cacheId, cacheVersion, cachedExperiment, isFetching, retrieveExperiment]
  );

  return {
    retrieveExperiment,
    mutateUpdatedExperiment,
  };
};
